import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders  } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

// Classes:
import { Beitrag } from './classes/beitrag';
import { Kurs } from './classes/kurs';
import { Vhs } from './classes/vhs';
import { Id } from './classes/id';
import { Track } from './classes/track';
import { User } from './classes/user';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL: string = 'https://wissensrouten-karte.de/api/';
  // apiURL: string = 'https://infodocks.de/api/wissensrouten/';
  httpOptions = {
    headers: new HttpHeaders({ 
      'Content-Type': 'application/json',
      'Authorization': 'g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ',
      'Access-Control-Allow-Origin':'*'
    })
  };

  constructor( private httpClient: HttpClient) { }

  public getBeitraege(url?: string): Observable<Beitrag[]>{
    return this.httpClient.get<Beitrag[]>(`${this.apiURL}beitraege`)
      .pipe(
        // tap(_ => this.log('fetched Beiträge')),
        catchError(this.handleError<Beitrag[]>('getBeitraege', []))
      );
  }
  public getBeitrag(id: number): Observable<Beitrag[]>{
    return this.httpClient.get<Beitrag[]>(`${this.apiURL}beitraege/`+id)
      .pipe(
        // tap(_ => this.log('fetched Beiträge')),
        catchError(this.handleError<Beitrag[]>('getBeitrag', []))
      );
  }
   public getBeitraegeZumKurs(id: number): Observable<Beitrag[]>{
    return this.httpClient.get<Beitrag[]>(`${this.apiURL}kurse/`+id+`/beitraege`)
      .pipe(
        // tap(_ => this.log('fetched Beiträge')),
        catchError(this.handleError<Beitrag[]>('getBeitrag', []))
      );
  } 
  public updateBeitrag(beitrag:Beitrag){
    //console.log(beitrag);
    const params = new FormData();
    for ( var key in beitrag ) {
      params.append(key, beitrag[key]);
    }
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    // TODO: Feedbackmeldung, dass gespeichert wurde.
    return this.httpClient.post(`${this.apiURL}beitraege/update`,params)
      .pipe(
        //tap(_ => this.log('update Beitrag')),
        catchError(this.handleError<Beitrag[]>('updateBeitrag'))
      );
  }
  public deleteBeitrag(id){
    const params = new FormData();
    params.append('id', id);
    params.append('table', 'wissensrouten');
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    console.log(params);
    return this.httpClient.post(`${this.apiURL}delete`,params)
      .pipe(
        //tap(_ => this.log('delete Beitrag')),
        catchError(this.handleError('deleteBeitrag'))
      );
  }
  public deleteKurs(id){
    const params = new FormData();
    params.append('id', id);
    params.append('table', 'kurse');
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    console.log(params);
    return this.httpClient.post(`${this.apiURL}delete`,params)
      .pipe(
        //tap(_ => this.log('delete Kurs')),
        catchError(this.handleError('deleteKurs'))
      );
  }
  public deleteVhs(id){
    const params = new FormData();
    params.append('id', id);
    params.append('table', 'vhs');
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    console.log(params);
    return this.httpClient.post(`${this.apiURL}delete`,params)
      .pipe(
        //tap(_ => this.log('delete Vhs')),
        catchError(this.handleError('deleteVhs'))
      );
  }
  
  public getVhsn(url?: number): Observable<Vhs[]>{
    return this.httpClient.get<Vhs[]>(`${this.apiURL}vhs`)
      .pipe(
        // tap(_ => this.log('fetched Vhs')),
        catchError(this.handleError<Vhs[]>('getVhs', []))
      );
  }
  public getVhs(id: number): Observable<Vhs[]>{
    return this.httpClient.get<Vhs[]>(`${this.apiURL}vhs/`+id)
      .pipe(
        // tap(_ => this.log('fetched Vhs')),
        catchError(this.handleError<Vhs[]>('getVhs', []))
      );
  }
  public updateVhs(el:Vhs){
    //console.log(el);
    const params = new FormData();
    for ( var key in el ) {
      params.append(key, el[key]);
    }
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    // TODO: Feedbackmeldung, dass gespeichert wurde.
    return this.httpClient.post(`${this.apiURL}vhs/update`,params)
      .pipe(
        //tap(_ => this.log('update Vhs')),
        catchError(this.handleError<Vhs[]>('updateVhs'))
      );
  }
  
  public getKurse(url?: string): Observable<Kurs[]>{
    return this.httpClient.get<Kurs[]>(`${this.apiURL}kurse`)
      .pipe(
        // tap(_ => this.log('fetched Kurs')),
        catchError(this.handleError<Kurs[]>('getKurse', []))
      );
  }
  public getKurs(id: number): Observable<Kurs[]>{
    return this.httpClient.get<Kurs[]>(`${this.apiURL}kurse/`+id)
      .pipe(
        // tap(_ => this.log('fetched Beiträge')),
        catchError(this.handleError<Kurs[]>('getKurs', []))
      );
  }
  public updateKurs(el:Kurs){
    //console.log(el);
    const params = new FormData();
    for ( var key in el ) {
      params.append(key, el[key]);
    }
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    // TODO: Feedbackmeldung, dass gespeichert wurde.
    return this.httpClient.post(`${this.apiURL}kurse/update`,params)
      .pipe(
        map((res:any) => { return res}),
        //tap(_ => this.log('update Kurs')),
        catchError(this.handleError<Kurs[]>('updateKurs'))
      );
  }

  public getIdFromShorturl(url?: string): Observable<Id>{
    return this.httpClient.get<Id>(`${this.apiURL}shorturl/`+url)
      .pipe(
        // tap(_ => this.log('fetched IdFromShorturl')),
        catchError(this.handleError<Id>('getIdFromShorturl'))
      );
  }
  public getIdFromShorturlLayer(url?: string): Observable<Id>{
    return this.httpClient.get<Id>(`${this.apiURL}shorturllayer/`+url)
      .pipe(
        // tap(_ => this.log('fetched IdFromShorturl')),
        catchError(this.handleError<Id>('getIdFromShorturlLayer'))
      );
  }
  public getIdFromShorturlOrg(url?: string): Observable<Id>{
    return this.httpClient.get<Id>(`${this.apiURL}shorturlorg/`+url)
      .pipe(
        // tap(_ => this.log('fetched IdFromShorturl')),
        catchError(this.handleError<Id>('getIdFromShorturlOrg'))
      );
  }

  public getUsers(masterpw: string):Observable<User[]>{
    const params = new FormData();
    params.append('masterpw',masterpw);
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    return this.httpClient.post(`${this.apiURL}users`,params)
      .pipe(
        //tap(_ => this.log('update Beitrag')),
        map((res:any) => { return res}),
        catchError(this.handleError<User[]>('getUsers'))
      );
  }

  public updateUser(el):Observable<User[]>{
    const params = new FormData();
    for ( var key in el ) {
      params.append(key, el[key]);
    }
    params.append('authkey','g5NL8inBFF07xiTMNWjO3qYgfhhLnWrKs6Iunas5EVgBr79EfQA3LYZ');
    return this.httpClient.post(`${this.apiURL}user/update`,params)
      .pipe(
        map((res:any) => { return res}),
        catchError(this.handleError<User[]>('updateUsers'))
      );
  }

  //console.log(el);


  // public getRoute(id: number): Observable<Track>{
  //   return this.httpClient.get<Track>(`${this.apiURL}route/`+id)
  //     .pipe(
  //       // tap(_ => this.log('fetched Route')),
  //       catchError(this.handleError<Track>('getRoute'))
  //     );
  // }

  /**
  * Handle Http operation that failed.
  * Let the app continue.
  * @param operation - name of the operation that failed
  * @param result - optional value to return as the observable result
  */
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  private log(message: string) { // Eigentlich als Benachrichtigungsfunktion innerhalb der Website gedacht.
    console.log(`API-Service: ${message}`);
  }

}
