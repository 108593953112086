<app-top-nav></app-top-nav>

<div class="ui container main">
    <div class="ui small breadcrumb">
            <a class="section" routerLink="/redaktion">Übersicht</a><i class="right chevron icon divider"></i>
            <a class="section" routerLink="/redaktion/beitraege">Beiträge</a><i class="right chevron icon divider"></i>
            <div class="active section">{{o.titel}}</div>
    </div>

    <h1 class="ui header">{{sitetitle}}</h1>
    <form [formGroup]="inForm" class="ui form" (ngSubmit)="onSubmit()">
        <input type="hidden" formControlName="id">
        <div class="field required"><label>Titel</label><input type="text" formControlName="titel"></div>
        <!-- <div class="field"><label>Kurz-URL zu diesem Beitrag:</label><input type="text" (keyup)="cleanUpUrl($event)" formControlName="shorturl"></div> -->
        <div class="field"><label>Kurz-URL zu diesem Beitrag:</label><input type="text" formControlName="shorturl"></div>
        <div><p>Direktlink zu diesem Beitrag: <a [href]="deeplink" target="_blank">{{deeplink}}</a></p><p> </p></div>
        <div class="field"><label>Tags, die diesem Beitrag beschreiben:</label><input type="text" formControlName="tags"></div>
        <!-- <div class="inline field"><div class="ui toggle checkbox"><input type="checkbox" tabindex="0" formControlName="status"><label>Beitrag freigeschaltet</label></div></div> -->
        <div class="three fields">
            <div class="field"><label>Geographische Breite</label><input type="text" formControlName="lat"></div>
            <div class="field"><label>Geographische Länge</label><input type="text" formControlName="lng"></div>
            <div class="field"><label>&nbsp;</label><div class="ui basic button" (click)="openMap()"><i class="icon map"></i>Ort auf der Karte festlegen</div></div>
        </div>
        
        <div class="ui segment">
        <div class="field"><label>Beitragsbild:</label><br><input type="text" formControlName="teaser_img" value="{{o.teaser_img}}"></div>
        <input type="file" name="f" (change)="onFileSelected($event)">
        <div class="ui button green" (click)="onUpload()">Bild hochladen</div>
        </div>

        <div class="field"><label>Beschreibung:</label>
        <editor formControlName="teaser" [init]="editorConfig"></editor>
        </div>

        <input type="hidden" formControlName="kurstitel">
        <input type="hidden" formControlName="vhs">
        <input type="hidden" formControlName="vhsid">
        <input type="hidden" formControlName="icon">
        <div class="field required"><label>Zugehöriger Kurs:</label>
            <div class="ui selection dropdown">
                <input type="hidden" name="kursid" formControlName="kursid" (change)="onChangeKurs($event.target.value)">
                <i class="dropdown icon"></i>
                <div class="default text">{{defaultText}}</div>
                <div class="menu">
                    <div [ngClass]="(kurs.id== o.kursid)?'item active selected':'item'" *ngFor="let kurs of kurse" [attr.data-value]="kurs.id" >{{kurs.name}}</div>
                </div>
            </div>
        </div>

        <div class="ui top attached tabular menu">
            <div class="active item" data-tab="audio">Audio-Datei</div>
            <div class="item" data-tab="youtube">Youtube-Link</div>
            <div class="item" data-tab="video">Video-Datei</div>
        </div>
        <div class="ui bottom attached active tab segment"  data-tab="audio">
            <div class="field"><label>Dateipfad</label><input type="text" formControlName="audio" placeholder=""></div>
            <label>Wenn Sie eine Audiodatei verwenden wollen, können Sie sie hier hochladen:</label>
            <input type="file" name="f" (change)="onFileSelectedAudio($event)">
            <div class="ui button green" (click)="onUploadAudio()">Datei hochladen</div>
        </div>
        <div class="ui bottom attached tab segment" data-tab="youtube">
            <div class="field"><label>Youtube-ID</label><input type="text" formControlName="youtube" placeholder="z.B. o6jC4YEEF8E"></div>
        </div>
        <div class="ui bottom attached tab segment" data-tab="video">
            <div class="field"><label>Dateipfad</label><input type="text" formControlName="video" placeholder=""></div>
            <label>Wenn Sie eine Videodatei verwenden wollen, können Sie sie hier hochladen:</label>
            <input type="file" name="f" (change)="onFileSelectedVideo($event)">
            <div class="ui button green" (click)="onUploadVideo()">Video hochladen</div>
        </div>
        

        <div class="field"><label>Ein Beitrag von:</label><editor formControlName="creators" [init]="editorConfig"></editor></div>

        <!-- <div class="field"><label>Mit Material aus folgenden Quellen:</label><textarea formControlName="credits" rows="4"></textarea></div> -->
        <div class="field"><label>Mit Material aus folgenden Quellen:</label><editor formControlName="credits" [init]="editorConfig"></editor></div>

        <!-- <div class="field"><label>Weiterführende Links:</label><textarea formControlName="links" rows="4"></textarea></div> -->
        <div class="field"><label>Weiterführende Links:</label><editor formControlName="links" [init]="editorConfig"></editor></div>

        <!-- <div class="field"><label>Mit Dank an:</label><textarea formControlName="dankan" rows="4"></textarea></div> -->
        <div class="field"><label>Mit Dank an:</label><editor formControlName="dankan" [init]="editorConfig"></editor></div>

        <!-- <div class="field"><label>Adresse</label><textarea formControlName="adr" rows="4"></textarea></div> -->
        <div class="field"><label>Adresse</label><editor formControlName="adr" [init]="editorConfig"></editor></div>
        
        <button class="ui submit primary button" (click)="onSubmit()" [disabled]="!inForm.valid">Speichern</button>
    </form>
    <p id="message">Die Daten wurden gespeichert.</p>
</div>

<!-- Zentral im Modal: -->
<div id="mapmodal" class="ui modal">
    <div class="scrolling content">
        <div class="ui grid">
            <div class="fourteen wide column">
              <h1>Wo liegt dieser Ort?</h1>
              <p>Klicken Sie in die Karte, um die Position festzulegen. Sie können jederzeit neu klicken oder die Markierung verschieben.</p>
            </div>
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom"
            [disableDefaultUI]="false"
            (mapClick)="mapClicked($event)">
              <!-- <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker> -->
              <agm-marker 
                *ngFor="let m of markers; let i = index"
                [latitude]="m.lat"
                [longitude]="m.lng"
                [label]="m.label"
                [markerDraggable]="m.draggable"
                (dragEnd)="markerDragEnd(m, $event)">       
            </agm-marker>
  
            </agm-map>
            <div class="ui approve positive button" (click)="closeMapmodal()">Ja, das ist der passende Ort</div>
        </div>
    </div>
  </div>
  
<app-footer></app-footer>

