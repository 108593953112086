import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders} from '@angular/common/http';
declare var $: any;
const URL = 'https://wissensrouten-karte.de/upload8943.php';

@Component({
  selector: 'app-layer-details',
  templateUrl: './layer-details.component.html',
  styleUrls: ['./layer-details.component.scss']
})
export class LayerDetailsComponent implements OnInit, OnDestroy  {
  id: number;
  sitetitle: string;
  deeplink: string = '';
  beitraege: any = [];
  private sub: any;
  public o: any = [];
  routePoiOrder: any = [];
  options: any = { animation: 150 }; // Für die sortables, brauche ich aber nur, um das update zu triggern
  editorConfig: any = {base_url: '/tinymce', suffix: '.min', menubar:false, branding: false, language: 'de', language_url: '../../assets/exogen/tinyMCElangs/de.js', plugins: 'lists advlist link wordcount paste', toolbar: 'undo redo | bold italic | bullist numlist outdent indent | link | paste', paste_as_text: true};
  inForm = this.fb.group({ 
    id: [''],
    name: [''],
    shorturl:new FormControl('',[this.validateShortUrl.bind(this)]),
    routentyp: [''],
    routenreihenfolge: [''],
    showkursinfo:[''],
    status: [''],
    vhs: [''],
    vhsid: [''],
    text:[''],
    links:[''],
    teaser_img: [''],
    gps:[''],
    gpx:[''],
    kml:['']
  });
  defaultText: string = 'Bitte auswählen'; // Semantic Dropdown
  defaultText2: string = 'Bitte auswählen'; // Semantic Dropdown
  vhsn: any = [];
  routentyp: number = 0;
  routentypen: any = ['keine Route anzeigen','Luftlinie zwischen ausgewählten Inhalten anzeigen','Einen eigenen GPS-Track anzeigen'];
  nichtTeilDerRoute = [];
  
  constructor(private route: ActivatedRoute, private apiService: ApiService, private http: HttpClient, private fb: FormBuilder, private router: Router) {
    this.options = {
      // Falls sich die Reihenfolge der Routenpunkte verändert:
      onUpdate: (event: any) => {
        this.updateRoute();
      }
    };

   }

  ngOnInit() {
    // Holt alle VHSn für die Dropdown-Auswahl:
    this.apiService.getVhsn().subscribe((res)=>{ 
      if(res){ this.vhsn = res; }
    });
    
    $('.ui.dropdown').dropdown();

    this.sub = this.route.params.subscribe(params => {
      if(params['id'] != 'neu'){
        this.sitetitle = 'Kurs bearbeiten';
        this.id = +params['id']; // (+) converts string 'id' to a number
        this.apiService.getKurs(this.id).subscribe((res)=>{ 
          this.o = res; 
          if(this.o.shorturl == null){this.o.shorturl ='';}
          if(this.o.routentyp == null){this.o.routentyp = 0;}
          //this.routentyp = this.routentypen[this.o.routentyp];
          this.routentyp = this.o.routentyp;
          //console.log('Routentyp: '+this.routentyp);

          this.defaultText2 = this.routentypen[this.o.routentyp];
          this.inForm = this.fb.group({ 
            id: [this.o.id],
            name: [this.o.name],
            shorturl: new FormControl(this.o.shorturl,[this.validateShortUrl.bind(this)]),
            routentyp: [this.o.routentyp],
            routenreihenfolge: [this.o.routenreihenfolge],
            showkursinfo:[this.o.showkursinfo],
            status: [this.o.status],
            vhs: [this.o.vhs],
            vhsid: [this.o.vhsid],
            text: [this.o.text],
            links: [this.o.links],
            teaser_img: [this.o.teaser_img],
            gps: [this.o.gps],
            gpx: [this.o.gpx],
            kml: [this.o.kml]
          });
          this.defaultText = this.o.vhs;
          // Öffnet das Feld mit der Reihenfolge, falls der Routentyp aus der DB 1
          this.routentyp = this.o.routentyp;  //console.log(this.routentyp);
        });

        this.apiService.getBeitraegeZumKurs(this.id).subscribe((res2)=>{ 
          if(res2){ 
            // Bringt die Beiträge in die richtige Reihenfolge aus der Route und fügt ihnen den Status (aktiv/nicht aktiv) hinzu,fall sie enthalten sind.
            if(this.o.routenreihenfolge){
              let rfArr = this.o.routenreihenfolge.split(',');
              //console.log(rfArr);
              let restArr = []; // Alle Beitraege, die nicht in der Route sind, werden später hinten dran gehängt.
              let tempArr: any = [];
              res2.forEach(function(e){
                let index = rfArr.indexOf(e.id.toString());
                if(index == -1){ // Nicht in der Route
                  e['route'] = false;
                  restArr.push(e);
                }
                else{
                  e['route'] = true;
                  tempArr[index] = e;
                }
              });
              this.beitraege = tempArr.concat(restArr); 
              //vorher: this.beitraege = res2;
              //console.log(this.beitraege);
            }
            else{
              this.beitraege = res2;
            }
          }
          }); 

      }else{
        this.sitetitle = 'Neuen Kurs anlegen';
        this.id = 0; // 0 = neu
        this.inForm.patchValue({'id': 0});
        this.inForm.patchValue({'vhsid': 0});
        this.inForm.patchValue({'status': 1});
        this.inForm.patchValue({'text': ''});
        this.inForm.patchValue({'shorturl': ''});
      }
   });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  onSubmit(){
    console.log('Form submitted');
    //console.log(this.inForm.value);
    this.apiService.updateKurs(this.inForm.value).subscribe((res)=>{ 
      if(res){
        $('body').toast({class: 'success',message: `gespeichert`});
      }
      else{ 
        $('body').toast({class: 'error',message: `Es ist ein Fehler aufgetreten.`}); 
      }
    });
    this.router.navigate(['/redaktion/kurse']);
  }

  // Upload teaser image:
  selectedFile: File = null;
  onFileSelected(event){ this.selectedFile = <File>event.target.files[0]; }
  onUpload(){
    const fd = new FormData();
    fd.append('f', this.selectedFile, this.selectedFile.name);
    this.http.post(URL, fd).subscribe(res => { if(res['status'] == 'success'){ this.inForm.patchValue({'teaser_img': res['filename']});} });
  }

  // Upload GPS-Tracks:
  selectedFileGps: File;
  onFileSelectedGps(event){ 
    this.selectedFileGps = <File>event.target.files[0]; 
    this.inForm.patchValue({'gps': <File>event.target.files[0].name});
    let fileReader = new FileReader();
    fileReader.readAsText(this.selectedFileGps);
    fileReader.onload = (e) => {
      //console.log(fileReader.result);
      this.inForm.patchValue({'kml': fileReader.result});
    }
  }
  onUploadGps(){
    let fileReader = new FileReader();
    fileReader.readAsText(this.selectedFileGps);
    fileReader.onload = (e) => {
      //console.log(fileReader.result);
      this.inForm.patchValue({'kml': fileReader.result});
    }
    
    // const fd2 = new FormData();
    // fd2.append('gps', this.selectedFile, this.selectedFile.name);
    // this.http.post(URL, fd2).subscribe(res => { if(res['status'] == 'success'){ this.inForm.patchValue({'gps': res['filename']});} });
  }

  
  onChangeVhs(vhsid){
    // Update the form:
    var vhs = this.vhsn.find(el => el.id == vhsid);
    this.inForm.patchValue({'vhsid': vhs.id});
    this.inForm.patchValue({'vhs': vhs.name});
    this.o.vhs = vhs.name;
  }
  onChangeRoutentyp(routentyp){
    
    this.routentyp = this.routentypen.indexOf(routentyp);
    this.inForm.patchValue({'routentyp': this.routentyp});
    this.defaultText2 = routentyp;
    console.log('Routentyp: '+this.routentyp);
    console.log(this.beitraege);
  }
  updateRoute(){
    let reihenfolgeIds = [];
        var nichtTeilDerRoute = this.nichtTeilDerRoute;
        //Reduziere Array der beitrage auf die IDs in der richtigen Reihenfolge
        this.beitraege.forEach(function(el){ 
          if(nichtTeilDerRoute.indexOf(el.id) == -1){
            reihenfolgeIds.push(el.id); 
          }
        });
        //console.log(reihenfolgeIds);
        // In das Form, damit sie auch in der DB abgespeichert werden. Routenerstellung erfolgt serverseitig in PHP.
        this.inForm.patchValue({'routenreihenfolge': reihenfolgeIds});
  }

  public addPoi(id){
    // console.log('Add: '+id);
    let index = this.nichtTeilDerRoute.indexOf(id);
    if(index > -1){
      this.nichtTeilDerRoute.splice(index,1);
    }
    // Buttonverwaltung per flag:
    for(var i = 0; i < this.beitraege.length; i += 1) {
      if(this.beitraege[i]['id'] === id) {
        this.beitraege[i]['route'] = true;
      }
    }
    // Feld aktualisieren:
    this.updateRoute();
    // console.log(this.nichtTeilDerRoute);
  }
  public removePoi(id){
    // console.log('Remove: '+id);
    if(this.nichtTeilDerRoute.indexOf(id) == -1){
      this.nichtTeilDerRoute.push(id);
    }
    // Buttonverwaltung per flag:
    for(var i = 0; i < this.beitraege.length; i += 1) {
      if(this.beitraege[i]['id'] === id) {
        this.beitraege[i]['route'] = false;
      }
    }
    // Feld aktualisieren:
    this.updateRoute();
    // console.log(this.nichtTeilDerRoute);
  }

  /* Validation  +++ */
  validateShortUrl(control: FormControl): {[s: string]: boolean}{

      // Trimmen:
      var vorher = control.value.trim();
      // URL-Sonderzeichen nicht zulassen:
      let re = /[?:@&=+$#'"]/g;
      let nachher = vorher.replace(re,'');
      // Leerzeichen durch Untertsirche ersetzen:
      let re2 = /\s/gi
      nachher = nachher.replace(re2,'_');
      // Generell ersetzen:
      var searchAndReplace: any = {ä:"ae",Ä:"Ae",ü:"ue",Ü:"Ue",ö:"oe",Ö:"Oe",ß:"ss"};
      for(var s in searchAndReplace){
        //console.log(s+' '+searchAndReplace[s]);
        let re3 = new RegExp(s,"g");
        nachher = nachher.replace(re3,searchAndReplace[s]);
      }
      // console.log('Validate shorturl: '+nachher);
      if(nachher !== vorher){ // Ändert nur, falls notwendig. Verhindert eine rekursive Schleife.
        this.inForm.patchValue({'shorturl': nachher}, {emitEvent:false});
      }
 
    if(nachher !== ''){
      this.deeplink = "https://www.wissensrouten-karte.de/kurs/"+nachher;
    }
    else{
      this.deeplink = "https://www.wissensrouten-karte.de/kurs/"+this.id;
    }
    return null;
  }

}
