export class Beitrag {
    id: number;
    vhs: string;
    vhsid: number;
    jahr: number;
    kurstitel: string;
    kursid: number;
    titel: string;
    youtube: string;
    audio: string;
    shorturl: string;
    video: string;
    lat: number;
    lng: number;
    adr: string;
    teaser: string;
    creators: string;
    credits: string;
    links: string;
    dankan: string;
    teaser_img: string;
    icon: string;
    status: number;
}