import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { DashboardComponent } from './dashboard/dashboard.component';
import { MapGoogleComponent } from './map-google/map-google.component';
import { PoiDetailsComponent } from './poi-details/poi-details.component';
import { PoiListComponent } from './poi-list/poi-list.component';

// import { RouteNewComponent } from './route-new/route-new.component';
// import { RouteDetailsComponent } from './route-details/route-details.component';
// import { RouteListComponent } from './route-list/route-list.component';
import { LayerDetailsComponent } from './layer-details/layer-details.component';
import { LayerListComponent } from './layer-list/layer-list.component';
import { OrgListComponent } from './org-list/org-list.component';
import { OrgDetailsComponent } from './org-details/org-details.component';
import { AuthGuard } from '../auth/_helpers';
import {BackenduserComponent} from './backenduser/backenduser.component'

const routes: Routes = [
  // { path: 'redaktion', component: DashboardComponent },
  { path: 'redaktion', component: PoiListComponent, canActivate: [AuthGuard] },
  { path: 'map', component: MapGoogleComponent },
  { path: 'map', component: MapGoogleComponent },
  { path: 'karte/:id', component: MapGoogleComponent },
  { path: 'redaktion/vhs', component: OrgListComponent, canActivate: [AuthGuard] },
  { path: 'redaktion/vhs/:id', component: OrgDetailsComponent, canActivate: [AuthGuard] },
  { path: 'redaktion/beitraege', component: PoiListComponent, canActivate: [AuthGuard]  },
  { path: 'redaktion/b/:id', component: PoiDetailsComponent, canActivate: [AuthGuard]  },
  { path: 'redaktion/kurse', component: LayerListComponent, canActivate: [AuthGuard]  },
  { path: 'redaktion/kurs/:id', component: LayerDetailsComponent, canActivate: [AuthGuard]  },
  { path: 'redaktion/nutzerverwaltung', component: BackenduserComponent, canActivate: [AuthGuard]  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GeoRoutingModule { }
