import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { MapGoogleComponent } from './geo/map-google/map-google.component';

const routes: Routes = [
  { path: '', component: MapGoogleComponent, pathMatch: 'full' },
  { path: 'beitrag/:id', component: MapGoogleComponent },
  { path: 'kurs/:id', component: MapGoogleComponent },
  { path: 'route/:id', component: MapGoogleComponent },
  { path: 'vhs/:id', component: MapGoogleComponent }
  // { path: '', component: MapGoogleComponent, pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
