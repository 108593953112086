<app-top-nav></app-top-nav>
<div class="ui container main">

        <div class="ui small breadcrumb">
        <a class="section" routerLink="/redaktion">Übersicht</a><i class="right chevron icon divider"></i>
        <div class="active section"> Nutzerverwaltung</div>
        </div>

        <h1 class="ui header">Nutzerverwaltung</h1>
        <div *ngIf="!pw">
            <p>Bitte geben Sie das Administratorpasswort ein:</p>
            <div class="ui input">
                <input type="text" id="pw" name="pw" placeholder="">
            </div>
            <button class="ui primary button" (click)="loginM()">Nutzerverwaltung aktivieren</button>
            <div *ngIf="wrongpw" class="ui error message">
                <p>Falsches Passwort</p>
            </div>
        </div>  
        
        <table *ngIf="pw" class="ui compact celled definition table">
        <thead>
        <tr>
                <!-- <th>Sichtbar</th> -->
                <th></th>
                <th>Login</th>
                <th>Passwort</th>
                <th></th>
                <th></th>
        </tr>
        </thead>
        <tbody>
                <tr *ngFor="let o of users">
                        <!-- <td class="collapsing"><div class="ui fitted slider checkbox"><input type="checkbox"> <label></label></div></td> -->
                        <td></td>
                        <td>{{o.username}}</td>
                        <td>{{o.pw}}</td>
                        <td><i (click)="openModal(o)" class="edit icon"></i></td>
                        <td><i (click)="openModalTrash(o)" class="trash alternate outline icon"></i></td>
                </tr>
        </tbody>
        </table>
</div>
<app-footer></app-footer>

<div class="ui modal modalDelete">
        <div class="ui icon header"><i class="trash alternate outline icon"></i>Nutzer löschen</div>  
        <div class="content"><p>Sind Sie sicher, dass sie den folgenden Nutzer jetzt löschen möchten?</p><h3>{{modalTitel}}</h3></div>
        <div class="actions">
                <div class="ui black deny button">Nein, abbrechen</div>
                <div class="ui positive right labeled icon button" >Ja, jetzt löschen<i class="checkmark icon"></i></div>
        </div>
</div>

<div class="ui modal modalEdit">
    <div class="ui icon header"><i class="edit alternate outline icon"></i>Nutzer {{modalTitel}} bearbeiten</div>  
    <form [formGroup]="userForm" class="ui form" (ngSubmit)="updateUser()">
        <div class="content">
                <div class="ui input">
                        <label>Login:</label>
                        <input type="text" name="nlogin" formControlName="nlogin">
                        <label>Passwort:</label>
                        <input type="text" name="npw" formControlName="npw">
                        <input type="hidden" name="ologin" formControlName="ologin">
                </div>
        </div>
        <div class="actions">
                <div class="ui black deny button">abbrechen</div>
                <button class="ui positive right labeled icon button" type="submit" [disabled]="!userForm.valid">speichern<i class="checkmark icon"></i></button>
        </div>
    </form>
</div>