import { Component, OnInit, OnDestroy,NgZone  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { Beitrag } from '../../classes/beitrag';
import { FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import mapStyleJSON from '../../../assets/map/googleMaps_style.json';
import { Router} from '@angular/router';
import { AgmMap } from '@agm/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
declare var $: any; // Für die jquery-Unterstützung

const URL = 'https://wissensrouten-karte.de/upload8943.php';

@Component({
  selector: 'app-poi-details',
  templateUrl: './poi-details.component.html',
  styleUrls: ['./poi-details.component.scss']
})
export class PoiDetailsComponent implements OnInit, OnDestroy {
  id: number;
  sitetitle: string;
  deeplink: string = '';
  private sub: any;
  public o: any = [];
  editorConfig: any = {base_url: '/tinymce', suffix: '.min', menubar:false, branding: false, language: 'de', language_url: '../../assets/exogen/tinyMCElangs/de.js', plugins: 'lists advlist link wordcount paste', toolbar: 'undo redo | bold italic | bullist numlist outdent indent | link | paste', paste_as_text: true};
  inForm = this.fb.group({ 
    id: [''],
    status: [''],
    titel: [''],
    teaser: [''],
    vhs: [''],
    vhsid: [''],
    kursid: [''],
    kurstitel: [''],
    tags: [''],
    icon: [''],
    youtube: [''],
    audio:[''],
    shorturl:new FormControl('',[Validators.required, this.validateShortUrl.bind(this)]),
    video:[''],
    adr: [''],
    lat: new FormControl('0',[Validators.required, this.validateLat.bind(this)]),
    lng: new FormControl('0',[Validators.required, this.validateLat.bind(this)]),
    creators: [''],
    credits: [''],
    dankan: [''],
    links: [''],
    teaser_img: ['']
  });
  
  public agmMap: AgmMap
  public q: any = [];
  public title = 'Google Maps'; 
  lat = 50.7; 
  lng = 8.668; 
  zoom: number = 8;
  public mapStyle: any = mapStyleJSON;
  markers: marker[] = []; 

  defaultText: string = 'Bitte auswählen'; // Semantic Dropdown
  kurse: any = []; // Für die Dropdownauswahl
  
  constructor(private route: ActivatedRoute, private http: HttpClient, private apiService: ApiService, private fb: FormBuilder, private router: Router) {
    
    $(document).ready(function(){
      $('.tabular.menu .item').tab({history:false});
    });
  }

  // file upload +++
  selectedFileAudio: File = null;
  onFileSelectedAudio(event){ this.selectedFileAudio = <File>event.target.files[0]; }
  onUploadAudio(){
    if(this.selectedFileAudio){
    const fd = new FormData();
      fd.append('f', this.selectedFileAudio, this.selectedFileAudio.name);
      this.http.post(URL, fd).subscribe(res => { if(res['status'] == 'success'){ this.inForm.patchValue({'audio': res['filename']});} });
    }
  }

  selectedFileVideo: File = null;
  onFileSelectedVideo(event){ this.selectedFileVideo = <File>event.target.files[0]; }
  onUploadVideo(){
    const fd = new FormData();
    fd.append('f', this.selectedFileVideo, this.selectedFileVideo.name);
    this.http.post(URL, fd).subscribe(res => { if(res['status'] == 'success'){ this.inForm.patchValue({'video': res['filename']});} });
  }
  selectedFile: File = null;
  onFileSelected(event){ this.selectedFile = <File>event.target.files[0]; }
  onUpload(){
    const fd = new FormData();
    fd.append('f', this.selectedFile, this.selectedFile.name);
    this.http.post(URL, fd).subscribe(res => { if(res['status'] == 'success'){ this.inForm.patchValue({'teaser_img': res['filename']});} });
  }

  onSubmit(){
    event.preventDefault(); 
    console.log('Form submitted');
    this.cleanUpInput();
    console.log(this.inForm.value);
    this.apiService.updateBeitrag(this.inForm.value).subscribe((res)=>{ });
    this.router.navigate(['/redaktion']);
  }
  /*cleanUpUrl(event){
    this.inForm.patchValue({'shorturl': event.target.value.replace(' ','_')});
  }*/
  cleanUpInput(){
    console.log('Clean up');
    let re = /\'/gi;
    let fields: string[] = ['credits','creators','dankan','links','adr','teaser','titel'];
    for(var f in fields){
      this.inForm.patchValue({[fields[f]]: this.inForm.get(fields[f]).value.replace(re,'&#39;')});
    }
    // Bereinige Leerzeichen aus dem Titel, damit ein führendes Leerzeichen nicht die Sortierreihenfolge beeinflusst:
    this.inForm.patchValue({'titel': this.inForm.value.titel.trim()});
  }

  ngOnInit() {
    // Holt alle Kurse für die Dropdown-Auswahl:
    this.apiService.getKurse().subscribe((res)=>{ 
      if(res){ this.kurse = res; }
    });
    $('.ui.dropdown').dropdown();

    this.sub = this.route.params.subscribe(params => {
      if(params['id'] != 'neu'){

        this.id = +params['id']; // (+) converts string 'id' to a number
        this.apiService.getBeitrag(this.id).subscribe((res)=>{ 
          this.o = res; 
          if(this.o.shorturl == null){this.o.shorturl ='';}
          this.inForm = this.fb.group({ 
            id: [this.o.id],
            status: [''],
            titel: [this.o.titel],
            teaser: [this.o.teaser],
            vhs: [this.o.vhs],
            vhsid: [this.o.vhsid],
            tags: [this.o.tags],
            kursid: [this.o.kursid],
            kurstitel: [this.o.kurstitel],
            youtube: [this.o.youtube],
            audio: [this.o.audio],
            shorturl: new FormControl(this.o.shorturl,[Validators.required, this.validateShortUrl.bind(this)]),
            video: [this.o.video],
            adr: [this.o.adr],
            lat: new FormControl(this.o.lat,[Validators.required, this.validateLat.bind(this)]),
            lng: new FormControl(this.o.lng,[Validators.required, this.validateLng.bind(this)]),
            creators: [this.o.creators],
            credits: [this.o.credits],
            dankan: [this.o.dankan],
            links: [this.o.links],
            teaser_img: [this.o.teaser_img]
          });
          this.defaultText = this.o.kurstitel;
        }); 
      }else{  
        this.sitetitle = 'Neuen Beitrag anlegen';
        this.id = 0; // 0 = neu
        this.inForm.patchValue({'id': 0});
        this.inForm.patchValue({'status': 1});
        this.inForm.patchValue({'lat': 0});
        this.inForm.patchValue({'lng': 0});
        this.inForm.patchValue({'kursid': 0});
        this.inForm.patchValue({'vhsid': 0});
        this.inForm.patchValue({'icon': 'blue'});
      }
   });
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  /* Validation  +++ */
  validateShortUrl(control: FormControl): {[s: string]: boolean}{

    // Trimmen:
    var vorher = control.value.trim();
    // URL-Sonderzeichen nicht zulassen:
    let re = /[?:@&=+$#'"]/g;
    let nachher = vorher.replace(re,'');
    // Leerzeichen durch Untertsirche ersetzen:
    let re2 = /\s/gi
    nachher = nachher.replace(re2,'_');
    // Generell ersetzen:
    var searchAndReplace: any = {ä:"ae",Ä:"Ae",ü:"ue",Ü:"Ue",ö:"oe",Ö:"Oe",ß:"ss"};
    for(var s in searchAndReplace){
      //console.log(s+' '+searchAndReplace[s]);
      let re3 = new RegExp(s,"g");
      nachher = nachher.replace(re3,searchAndReplace[s]);
    }
    // console.log('Validate shorturl: '+nachher);
    if(nachher !== vorher){ // Ändert nur, falls notwendig. Verhindert eine rekursive Schleife.
      this.inForm.patchValue({'shorturl': nachher}, {emitEvent:false});
    }

    if(nachher !== ''){
      this.deeplink = "https://www.wissensrouten-karte.de/beitrag/"+nachher;
    }
    else{
      this.deeplink = "https://www.wissensrouten-karte.de/beitrag/"+this.id;
    }
    return null;
  }

 
  validateLat(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(!isNaN(v)){
      if(v >= -60 && v <= 60){ // Mögliche Werte
        return null;
      }
    }
    else{ // Keine gültige Zahl
      v = v.replace(',','.'); // Ggf. Komma durch Punkt automatisch ersetzen
      if(!isNaN(v)){ // Jetzt eine Zahl?
        if(v >= -60 && v <= 60){ // Mögliche Werte
          this.inForm.patchValue({'lat': v}); // Wert im Formular ersetzen
          return null;
        }
      }
    }
    // Wenn alles nichts hilft:
    return {'Geographische Breite ist ungültig': true};
  }
  validateLng(control: FormControl): {[s: string]: boolean}{
    var v = control.value;
    if(!isNaN(v)){
      if(v >= -180 && v <= 180){ // Mögliche Werte
        return null;
      }
    }
    else{ // keine gültige Zahl
      v = v.replace(',','.'); // Ggf. Komma durch Punkt automatisch ersetzen
      if(!isNaN(v)){ // Jetzt eine Zahl?
        if(v >= -60 && v <= 60){ // Mögliche Werte
          this.inForm.patchValue({'lng': v}); // Wert im Formular ersetzen
          return null;
        }
      }
    }
    // Wenn alles nichts hilft:
    return {'Geographische Länge ist ungültig': true};
  }
  /* Validation  --- */
  
  openMap(){
    $('#mapmodal').modal('show');
    if(this.inForm.value.lat && this.inForm.value.lng ){
      this.markers = [];
      this.markers.push({lat: this.inForm.value.lat,lng: this.inForm.value.lng,draggable: true});
      this.lat = this.inForm.value.lat;
      this.lng = this.inForm.value.lng;
    }
    // this.inForm.patchValue({'lat':'223434'});
    // this.agmMap.triggerResize();
  }
  closeMapmodal(){
    $('#mapmodal').modal('hide');
  }

  mapClicked($event: MouseEvent){
    // console.log('map click');
    console.log($event);
    this.markers = [];
    this.markers.push({lat: $event['coords'].lat,lng: $event['coords'].lng,draggable: true});
    this.inForm.patchValue({'lat': $event['coords'].lat});
    this.inForm.patchValue({'lng': $event['coords'].lng});
  }
  markerDragEnd(m: marker, $event: MouseEvent) {
    // console.log('dragEnd', m, $event);
    this.inForm.patchValue({'lat': $event['coords'].lat});
    this.inForm.patchValue({'lng': $event['coords'].lng});
  }

  onChangeKurs(kursid){
    // Update the form:
    var kurs = this.kurse.find(el => el.id == kursid);
    this.inForm.patchValue({'kursid': kurs.id});
    this.inForm.patchValue({'kurstitel': kurs.name});
    this.inForm.patchValue({'vhsid': kurs.vhsid});
    this.inForm.patchValue({'vhs': kurs.vhs});
    this.o.kurstitel = kurs.name;
  }

}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}