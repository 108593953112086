<app-top-nav></app-top-nav>
<div class="ui container main">
    <div class="ui small breadcrumb">
            <a class="section" routerLink="/redaktion">Übersicht</a><i class="right chevron icon divider"></i>
            <a class="section" routerLink="/redaktion/vhs">Alle Einrichtungen</a><i class="right chevron icon divider"></i>
            <div class="active section">{{o.name}}</div>
    </div>
    <h1 class="ui header">{{sitetitle}}</h1>
    <form [formGroup]="inForm" class="ui form" (ngSubmit)="onSubmit()">

        <input type="hidden" formControlName="id">
        <input type="hidden" formControlName="status">
        <div class="field required"><label>Name</label><input type="text" formControlName="name"></div>
        <div class="field"><label>Kurz-URL zu dieser VHS:</label><input type="text" formControlName="shorturl">
        <div><p>Direktlink zu allen Beiträgen dieser VHS: <a [href]="deeplink" target="_blank">{{deeplink}}</a></p></div>
        </div>
        <div class="field"><label>Beschreibungstext:</label><editor formControlName="text" [init]="{base_url: '/tinymce', suffix: '.min', menubar:false, branding: false, language: 'de', language_url: '../../assets/exogen/tinyMCElangs/de.js', plugins: 'lists advlist link wordcount', toolbar: 'undo redo | bold italic | bullist numlist outdent indent | link'}"></editor></div>
        
        <button class="ui submit primary button" type="submit" [disabled]="!inForm.valid">Speichern</button>
    </form>
    <p id="message">Die Daten wurden gespeichert.</p>
</div>
<app-footer></app-footer>