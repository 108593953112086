import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ImprintComponent } from './imprint/imprint.component';
import { Error404Component } from './error404/error404.component';
import { ContactComponent } from './contact/contact.component';
import { DataPolicyComponent } from './data-policy/data-policy.component';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';

const routes: Routes = [
  { path: 'imprint', component: ImprintComponent },
  { path: 'impressum', component: ImprintComponent },
  { path: '404', component: Error404Component },
  { path: 'contact', component: ContactComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'data-policy', component: DataPolicyComponent },
  { path: 'datenschutzerklärung', component: DataPolicyComponent },
  { path: 'start', component: HomeComponent },
  { path: 'info', component: AboutComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
