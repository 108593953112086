import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { AgmCoreModule } from '@agm/core';
//import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';
import { EditorModule } from '@tinymce/tinymce-angular';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { SortablejsModule } from 'ngx-sortablejs';

import { GeoRoutingModule } from './geo-routing.module';
import { PoiDetailsComponent } from './poi-details/poi-details.component';
import { MapLeafletComponent } from './map-leaflet/map-leaflet.component';
import { PoiListComponent } from './poi-list/poi-list.component';
import { RouteListComponent } from './route-list/route-list.component';
import { RouteDetailsComponent } from './route-details/route-details.component';
import { LayerListComponent } from './layer-list/layer-list.component';
import { LayerDetailsComponent } from './layer-details/layer-details.component';
import { MapGoogleComponent } from './map-google/map-google.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TopNavComponent } from './top-nav/top-nav.component';
import { FooterComponent } from './footer/footer.component';
import { OrgListComponent } from './org-list/org-list.component';
import { OrgDetailsComponent } from './org-details/org-details.component';
import { BackenduserComponent } from './backenduser/backenduser.component';


@NgModule({
  declarations: [
    PoiDetailsComponent,
    MapLeafletComponent,
    PoiListComponent,
    RouteListComponent, 
    RouteDetailsComponent, 
    LayerListComponent,  
    LayerDetailsComponent, 
    MapGoogleComponent, 
    DashboardComponent, 
    TopNavComponent, 
    FooterComponent, OrgListComponent, OrgDetailsComponent, BackenduserComponent
  ],
  imports: [
    CommonModule,
    GeoRoutingModule,
    EditorModule,
    SortablejsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBBqLY90B0zOx-SRjkvfwwTSGMZ0Cq_dF8'
    }),
    //AgmJsMarkerClustererModule,
    AgmMarkerClustererModule,
    AgmSnazzyInfoWindowModule,
    NgxAudioPlayerModule,
    ReactiveFormsModule
  ]
})
export class GeoModule { }
