import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Router} from '@angular/router';
import { ApiService } from '../../api.service';
import mapStyleJSON from '../../../assets/map/googleMaps_style.json';
import { Beitrag } from '../../classes/beitrag';
import { reduce } from 'rxjs/operators';
declare var $: any; // Für die jquery-Unterstützung
const BASEURL = "https://wissensrouten-karte.de/";

@Component({
  selector: 'app-map-google',
  templateUrl: './map-google.component.html',
  styleUrls: ['./map-google.component.scss']
  // encapsulation: ViewEncapsulation.None
})
export class MapGoogleComponent implements OnInit, OnDestroy {
  // Für die Deeplinks:
  id: number;  // BeitragsID aus der URL
  private sub: any;
  public o: any = [];
  audioPlaying = false;

  // Routen:
  track: any;
  kml: any;
  showTrack: boolean = false;
  showKmlTrack: boolean = false;
  public styleGeojson = {
    strokeColor: "#30588A",
    strokeOpacity: 0.9,
    fillOpacity: 0.1,
    clickable: false,
    strokeWeight: 2
  };

  // Die Karte:
  public title = 'Google Maps'; 
  lat = 50.7; 
  lng = 8.668; 
  zoom: number = 8;
  bounds: any;
  public mapStyle: any = mapStyleJSON;
  public markers: any[];
  public vhs: any[];
  public kurse: any[];

  // Aktuell auf der Karte zu sehen:
  public activeMarkers: any[];

  //Für das InfoWindow
  public permalink: string;
  public showKursinfo: boolean = false;

  // Icons:
  public mapicon: any = {url: '../../../assets/map/mapmarker_blue.svg', scaledSize: {width: 25,height: 50}}; // Default icon
  public mapicon_blue: any = {url: '../../../assets/map/mapmarker_blue.svg', scaledSize: {width: 25,height: 50}};
  public mapicon_red: any = {url: '../../../assets/map/mapmarker_red.svg', scaledSize: {width: 25,height: 50}};
  public mapicon_green: any = {url: '../../../assets/map/mapmarker_green.svg', scaledSize: {width: 25,height: 50}};
  public mapicon_orange: any = {url: '../../../assets/map/mapmarker_orange.svg', scaledSize: {width: 25,height: 50}};
  public mapicon_petrol: any = {url: '../../../assets/map/mapmarker_petrol.svg', scaledSize: {width: 25,height: 50}};
  public mapiconHover: any = {url: '../../../assets/map/mapmarkerHover.svg', scaledSize: {width: 25,height: 50}}; // Hover
  public lastMarkerIcon : any;

  // Aktuelle Auswahl (zum stylen in Menüsund auf der Karte)
  public selectedPoi: string = '-9999'; // Um zufällig leere Titel nicht zu triggern.
  public poi: any = Beitrag;
  public kurs: any = {}; // Aktuelle Kursauswahl
  public kursbeitraege: any[] = []; // Beiträge des aktuell ausgewählten Kurses
  public teaser_img = '';
  public teaser_img_kurs = '';

  msbapTitle = 'Audio Title';
  msbapAudioUrl = 'https://wissensrouten-karte.de/media/617125-01-wahab-abid-von-bagdad-nach-göttingen.mp3';   
  msbapDisplayTitle = false; 
  msbapDisplayVolumeControls = true; 
  videoUrl ='';

  constructor(private apiService: ApiService,private route: ActivatedRoute,private router: Router) { }
  ngOnInit() { 
    // this.id = +this.route.params['id']; // (+) converts string 'id' to a number
    // console.log(this.id);

    this.apiService.getVhsn().subscribe((res)=>{ this.vhs = res; });
    this.apiService.getKurse().subscribe((res)=>{ this.kurse = res; });
    this.apiService.getBeitraege().subscribe((res)=>{ 
      this.markers = res; this.activeMarkers = res; 

      // Steuerungsfunktion, wenn in der URL ein Deeplink eingegeben wurde. Erst aufgerufen, wenn auch die Marker eingetroffen sind:
      if(window.location.pathname != "/"){
        let pn = window.location.pathname.substring(1);
        let url_params = pn.split('/'); 
        if(url_params[1]){// Falls noch eine ID oder ein Titel gesetzt wurde
          let urlid = url_params[1];
          var urlnr = null;
          if(parseInt(urlid) != NaN){
            urlnr = parseInt(urlid);
          }
          switch(url_params[0]) {
            case 'beitrag':
              this.sub = this.route.params.subscribe((params) => {
                var tmp_id = params['id']; // (+) converts string 'id' to a number
                //console.log('roh: '+tmp_id);
                if(tmp_id){
                  if(isNaN(tmp_id)){
                    this.apiService.getIdFromShorturl(tmp_id).subscribe((res)=>{ 
                      if(res){
                        this.id = res['id']; 
                        this.apiService.getBeitrag(this.id).subscribe((res)=>{ 
                          this.o = res;
                          this.selectMarker(this.o,0);
                        });
                      }
                      // console.log(this.id);
                    });
                  }
                  else{ 
                    this.id = tmp_id; 
                    this.apiService.getBeitrag(this.id).subscribe((res)=>{ 
                      this.o = res;
                      this.selectMarker(this.o,0);
                    });
                  }
                }
              });
              break;
            case 'route':
            case'kurs':
              if(urlnr){
                this.selectThema(urlnr);
              }
              else{
                this.apiService.getIdFromShorturlLayer(urlid).subscribe((res)=>{ 
                  if(res){
                    urlnr = res['id']; 
                    console.log(urlnr);
                    this.selectThema(urlnr);
                  }
                });

              }
              break;
            case 'vhs':
              if(urlnr){
                this.selectVhs(urlnr);
              }
              else{
                this.apiService.getIdFromShorturlOrg(urlid).subscribe((res)=>{ 
                  if(res){
                    urlnr = res['id']; 
                    this.selectVhs(urlnr);
                  }
                });
              }
              break;
            default:

          }
        }
      }

    });

    var sidebarMaps = $('#sidebarMaps').sidebarMaps();
    $(".close").click(function(){ 
      $('.ui.modal').modal('hide');
    });
    $( document ).ready(function() {
      // RM deaktivieren:
      // FIXME: $('body').attr('oncontextmenu','return false;') ;

      $('.ui.sidebar.sb').sidebar({
        context: $('.ui.pushable.segment'),
        transition: 'overlay'
      }).sidebar('attach events', '#mobile_item');

      $(".agm-info-window-content").css({overflow: "hidden", color: "#30588A"});
      $(".openbtn").on("click", function() {
        $(".ui.sidebar").toggleClass("very thin icon");
        $(".asd").toggleClass("marginlefting");
        $(".sidebar z").toggleClass("displaynone");
        $(".ui.accordion").toggleClass("displaynone");
        // $(".ui.dropdown.item").toggleClass("displayblock");
        $('.ui.dropdown').dropdown();
        $(".logo").find('img').toggle();
      });
      $(".ui.dropdown").dropdown({
        allowCategorySelection: true,
        transition: "fade up",
        context: 'sidebar',
        on: "hover"
      });
      $('.ui.accordion').accordion({ selector: { } });
      $('.ui.modalSpotinfo').modal({
        onVisible: function () {
          // console.log('visible');
          this.audioPlaying = false;
        },
        onHide: function() {
          // console.log('dimmer clicked');
          this.audioPlaying = false;
          var tmp_yt_src = $("#youtube-video").attr("src");
          $("#youtube-video").attr("src",tmp_yt_src);

          $("audio").trigger('pause');
          this.audioPlaying = false;
          // var tmp_src = $("audio").attr("src");
          // $("audio").attr("src",tmp_src);
          // $("button.ngx-basic-audio-player-button-handler").on('click',function(){
          //   $("#spotinfo .audio_img").toggleClass('active');
          // });

          var tmp_src_v = $("video").attr("src");
          $("video").attr("src",tmp_src_v);
        }
      });
      // Tauscht das Bild, wenn der Audioplayer startet:
      var audio = document.getElementsByTagName("audio")[0];
      audio.onplay = function() { $(".audio_img").toggleClass('active'); }; 
      audio.onpause = function() { $(".audio_img").toggleClass('active'); }; 
    });
  }
  
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  clickTeaserImg(){
    $(".audio_img").toggleClass('active');
    // Alter Player: $("button.ngx-basic-audio-player-button-handler").click(); //start/stop für das audio
    if(this.audioPlaying){
      $("audio").trigger('pause');
      this.audioPlaying = false;
    }else{
      $("audio").trigger('play');
      this.audioPlaying = true;
    }
  }

  clickedMarker(p: any, index: number) { // by clicking on the icon
    this.openInfoWindow(p);
  }
  selectMarker(p: any, index: number) { 
    $('.ui.modalSpotinfo').modal('hide');
    // URL anpassen, ohne neu aufzurufen:
    if('' != p.shorturl && null !== p.shorturl){ history.replaceState('', 'Beitrag', '/beitrag/'+p.shorturl); }
    else{ history.replaceState('', 'Beitrag', '/beitrag/'+p.id); }

    this.activeMarkers = this.markers;

    // TODO: neue Bounds für den Marker:

    // Zoomfunktion:
    this.zoom = 16;
    this.lat = p.lat;
    this.lng = p.lng;
    

    this.openInfoWindow(p);
    // TODO: highlight marker
    console.log(`clicked the marker:`+p.id+' lat: '+this.lat+' lng: '+this.lng);
  }

  openInfoWindow(p){
    $("#sidebarMaps").addClass("collapsed");
    $("#sidebarMaps li").removeClass("active");
    this.selectedPoi = p.titel;
    this.poi = p;
    this.teaser_img = (p.teaser_img ? BASEURL+p.teaser_img : BASEURL+'media/empty.png');

    // Audio vorbereiten:
    if(p.audio){
      var audio = document.getElementsByTagName("audio")[0];
      this.msbapAudioUrl = BASEURL+p.audio;
      audio.src = this.msbapAudioUrl;
      this.msbapTitle = p.titel;
      this.msbapDisplayTitle = p.titel;
    }
    if(p.video){
      this.videoUrl = BASEURL+p.video;
    }
    else{this.videoUrl = '';}

    if(p.shorturl){ this.permalink = BASEURL+'beitrag/'+p.shorturl; }
    else{ this.permalink = BASEURL+'beitrag/'+p.id; }
    
    // Open in Sidebar:
    // $("#gm_content div.media").html('<iframe width="100%" height="60%" src="http://www.youtube.com/embed/'+p.youtube+'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
    // $("#gm_content").fadeIn(500,'swing');

    // Open in Modal:
    if(p.video && p.video != 'null'){
      $(".ui.modalSpotinfo div.media").html('');
      $('.video').show();
      $('.media').hide(); 
      $('.audio').hide(); 
    }else if(p.audio && p.audio != 'null'){
      // console.log('audio: '+p.audio);
      $(".ui.modalSpotinfo div.media").html('');
      $('.audio').show();
      $('.media').hide(); 
      $('.video').hide(); 
    }else{
      $('.audio').hide();
      $('.media').show(); 
      $('.video').hide();
      $(".ui.modalSpotinfo div.media").html('<iframe id="youtube-video" height="400px" width="100%" src="https://www.youtube-nocookie.com/embed/'+p.youtube+'?modestbranding=1&showinfo=0&rel=0&iv_load_policy=3&theme=light&color=white" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
      // Normaler Aufruf: $(".ui.modalSpotinfo div.media").html('<iframe id="youtube-video" height="400px" width="100%" src="https://www.youtube.com/embed/'+p.youtube+'" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>');
    }
    $('.ui.modalSpotinfo').modal('show');
  }
  
  
  mapClicked($event: MouseEvent) {
    $("#gm_content").fadeOut();
    $('.ui.modalSpotinfo').modal('hide');
    // console.log($event['coords']);
    // this.markers.push({lat: $event['coords'].lat,lng: $event['coords'].lng,draggable: true});
  }
  markerDragEnd(m: marker, $event: MouseEvent) { console.log('dragEnd', m, $event); }
  // Ich missbrauche das infoWindow als Tooltip, weil agm noch keine Tooltipps untertstützt (title dann deaktivieren, er lässt sich eh nicht stylen): 
  onMouseOver(infoWindow, $event: MouseEvent,m) { 
    infoWindow._openInfoWindow(); //snazzy
    // altes Infowindow: infoWindow.open(); 
    // Save the current icon
    this.lastMarkerIcon = m.icon;
    m.icon = this.mapiconHover;
    // console.log(event);
  }
  onMouseOut(infoWindow, $event: MouseEvent,m) { 
    infoWindow._closeInfoWindow(); //snazzy
    // altes Infowindow:  infoWindow.close(); 
    // Reset the icon:
    m.icon = this.lastMarkerIcon;
  }
  toggleOverlays(event, item){ }

  prepareText(t){
    t = t.replace(/http\S+/,"<a href='$1' target='_blank'>$1</a>");
    return(t);
  }

  // Layers:
  selectThema(id){ 
    let k:any;
    this.apiService.getKurs(id).subscribe((res)=>{ k = res; 
      if(!k.shorturl){k.shorturl = '';}
      //console.log(k);
      // Karte anpassen:
      this.filterMarker('kursid',id); 
      // URL anpassen, ohne neu aufzurufen:
      if('' != k.shorturl && null !== k.shorturl){ history.replaceState('', 'Kurs', '/kurs/'+k.shorturl); }
      else{ history.replaceState('', 'Kurs', '/kurs/'+id); }
      if(k.routentyp == '1'){
        //this.kursid = k.id;
        this.track = 'https://wissensrouten-karte.de/api/route/'+k.id;
        this.showTrack = true;
        this.showKmlTrack = false;
      }
      else if(k.routentyp == '2'){
        let random = Math.random();
        this.kml = 'https://wissensrouten-karte.de/api/kml/'+random+'/'+k.id+'.kml';
        this.showTrack = false;
        this.showKmlTrack = true;
        console.log(this.kml);
      }
      else{
        this.track = 'https://wissensrouten-karte.de/api/route/0';
        this.showTrack = false;
        this.showKmlTrack = false;
      }

      //TODO: Open Modal:
      if(k.showkursinfo == 1){ // testweise erst einmal bei diesem Kurs
        this.showKursinfo = true;
        if(k.shorturl){ k.permalink = BASEURL+'kurs/'+k.shorturl; }
        else{ k.permalink = BASEURL+'kurs/'+k.id; }
        this.kurs = k; // verfügbar für das Template
        this.teaser_img_kurs = (k.teaser_img ? BASEURL+k.teaser_img : BASEURL+'media/teaser_img_kurs.png');

        // Hol die Beiträge zu den Kursen:
        this.apiService.getBeitraegeZumKurs(id).subscribe((res2)=>{ 
          if(res2){ 
            this.kursbeitraege = res2;
          }
        });
        
      }
      else{
        this.showKursinfo = false;
      }
      
    });
  }
  // trackStylize(feature: google.maps.Data.Feature): google.maps.Data.StyleOptions {
  //   console.log('stylizing feature:', feature);
  //     return {
  //       strokeColor: "#30588A",
  //       strokeOpacity: 0.9,
  //       fillOpacity: 0.1,
  //       clickable: false,
  //       strokeWeight: 2
  //     };
  // }

  openKursinfo(){
    $('.ui.modalKursinfo').modal('show');
  }

  selectVhs(id,shorturl=''){ 
    // Karte anpassen:
    this.filterMarker('vhsid',id); 
    // URL anpassen, ohne neu aufzurufen:
    if('' != shorturl && null !== shorturl){ history.replaceState('', 'VHS', '/vhs/'+shorturl); }
    else{ history.replaceState('', 'Kurs', '/vhs/'+id); }
  }
  filterMarker(attrname,filtervalue){
    $("#sidebarMaps").addClass("collapsed");
    $("#sidebarMaps li").removeClass("active");
    var tmp = [];
    this.markers.forEach(e => {
      if(e[attrname] == filtervalue){ tmp.push(e); }
    });
    this.activeMarkers = tmp;
    // console.log(tmp);    
  }

  getIcon(iconname){
    switch(iconname){
      // case 'blue':
      //   return this.mapicon_blue;
      //   break;
      // case 'red':
      //   return this.mapicon_red;
      //   break;
      // case 'orange':
      //   return this.mapicon_orange;
      //   break;
      // case 'green':
      //   return this.mapicon_green;
      //   break;
      // case 'petrol':
      //   return this.mapicon_petrol;
      //   break;
      default:
        return this.mapicon;
    }
  }
}

// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

