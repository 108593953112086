import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
declare var $: any;

@Component({
  selector: 'app-poi-list',
  templateUrl: './poi-list.component.html',
  styleUrls: ['./poi-list.component.scss']
})
export class PoiListComponent implements OnInit {
  beitraege: any [];
  modalTitel: string;
  modalId : number;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getBeitraege().subscribe((res)=>{ this.beitraege = res; });
  }

  delete(id){
    // console.log(id);
    this.apiService.deleteBeitrag(id).subscribe((res)=>{ 
      if(res) console.log(res); 
      this.apiService.getBeitraege().subscribe((res)=>{ this.beitraege = res; });
    });
    
  }
  openModal(o){
    this.modalTitel = o.titel;
    this.modalId = o.id;
    $('.ui.modalDelete').modal('show');
  }
}
