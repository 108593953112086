<div class="ui grid" id="mainmenu">
  <div class="computer only row">
      <div class="column cp">
        <div class="ui menu">
          <a href="https://www.wissensrouten.de/" class="item">Start</a>
          <a href="https://www.wissensrouten.de/blog" class="item">Blog</a>
          <a class="item activeNav" href="">Karte</a>
          <a class="item" href="https://www.wissensrouten.de/#Wissensrouten-KurseanVolkshochschulen">Wissensrouten-Kurse</a>
          <a class="item" href="http://www.wissensrouten.de/impressum-datenschutz">Impressum und Datenschutz</a>
        </div>
    </div>
  </div>

  <div class="tablet mobile only row">
    <div class="column">
      <div class="ui menu">
        <a id="mobile_item" class="item"><i class="bars icon"></i></a>
      </div>
    </div>
  </div>

</div>

<div class="ui pushable segment">
  <div class="ui sidebar sb vertical menu">
      <a href="https://www.wissensrouten.de/" class="item">Start</a>
      <a href="https://www.wissensrouten.de/blog" class="item">Blog</a>
      <a class="item activeNav" href="">Karte</a>
      <a class="item" href="https://www.wissensrouten.de/#Wissensrouten-KurseanVolkshochschulen">Wissensrouten-Kurse</a>
      <a class="item" href="http://www.wissensrouten.de/impressum-datenschutz">Impressum und Datenschutz</a>
  </div>

  <div class="pusher">
    <div id="sidebarMaps" class="sidebarMaps collapsed">
      <!-- Nav tabs -->
      <div class="sidebarMaps-tabs">
          <ul role="tablist">
              <!-- <li><a href="#home" role="tab"><i class="fa fa-search-location"></i></a></li> -->
              <li><a href="#beitraege" role="tab"><i class="icon play"></i></a></li>
              <li><a href="#themen" role="tab"><i class="fa fa-map-marked-alt"></i></a></li>
              <li><a href="#vhs" role="tab"><img id="vhs-icon" src="/assets/img/icons/vhs.svg"/></a></li>
              <!-- <li class="disabled"><a href="#messages" role="tab"><i class="fa fa-envelope"></i></a></li> -->
              <!-- <li><a href="https://github.com/Turbo87/sidebar-v2" role="tab" target="_blank"><i class="fa fa-github"></i></a></li> -->
          </ul>
          <ul role="tablist">
              <!-- <li><a routerLink="/redaktion"><i class="fa fa-sign-in-alt"></i></a></li> -->
              <li><a href="/redaktion" target="_blank"><i class="fa fa-sign-in-alt"></i></a></li>
          </ul>
      </div>

      <!-- Tab panes -->
      <div class="sidebarMaps-content">
          <div class="sidebarMaps-pane" id="home">
              <h1 class="sidebarMaps-header">
                  Wissensrouten
                  <span class="sidebarMaps-close"><i class="fa fa-caret-left"></i></span>
              </h1>
              <!-- <div class="ui search">
                  <div class="ui icon input">
                    <input class="prompt" type="text" placeholder="">
                    <i class="search icon"></i>
                  </div>
                  <div class="results"></div>
                </div> -->
          </div>
          <div class="sidebarMaps-pane" id="themen">
              <h1 class="sidebarMaps-header">Wissensrouten:<span class="sidebarMaps-close"><i class="fa fa-caret-left"></i></span></h1>
              <div class="ui divided items"><div class="item" *ngFor="let o of kurse"><div class="content" (click)="selectThema(o.id)"><a>{{o.name}}</a></div></div></div>
          </div>
          <div class="sidebarMaps-pane" id="vhs">
            <h1 class="sidebarMaps-header">Beiträge der vhs:<span class="sidebarMaps-close"><i class="fa fa-caret-left"></i></span></h1>
            <div class="ui divided items"><div class="item" *ngFor="let o of vhs"><div class="content" (click)="selectVhs(o.id, o.shorturl)"><a>{{o.name}}</a></div></div></div>
        </div>
        <div class="sidebarMaps-pane" id="beitraege">
          <h1 class="sidebarMaps-header">Alle Beiträge:<span class="sidebarMaps-close"><i class="fa fa-caret-left"></i></span></h1>
          <div class="ui divided items"><div class="item" *ngFor="let o of markers" (click)="selectMarker(o,0)" [class.selected]="o.titel === selectedPoi"><div class="content"><a>{{o.titel}}</a></div></div></div>
        </div>
          <!-- <div class="sidebarMaps-pane" id="messages">
              <h1 class="sidebarMaps-header">Messages<span class="sidebarMaps-close"><i class="fa fa-caret-left"></i></span></h1>
          </div>
          <div class="sidebarMaps-pane" id="settings">
              <h1 class="sidebarMaps-header">Settings<span class="sidebarMaps-close"><i class="fa fa-caret-left"></i></span></h1>
          </div> -->
      </div>
    </div>
    <div id="info" *ngIf="showKursinfo" (click)="openKursinfo()">
      <span class="fa-stack" style="vertical-align: top;">
        <i class="fas fa-3x fa-circle fa-stack-2x"></i>
        <i class="fas fa-3x fa-info-circle fa-stack-1x"></i>
      </span>
    </div>

    <!-- Öffnen in der Sidebar: -->
    <!-- <div id="gm_content" class="sidebarMaps">
      <p class="right"><i id="close" class="close icon"></i></p>
      <h1>{{poi.kurstitel}} ({{poi.jahr}})</h1>
      <div class="youtube"></div>
      <h2>{{poi.titel}}</h2>
      <p>{{poi.vhs}}</p>
      <p>{{poi.teaser}}</p>
      <div *ngIf="poi.adr"><h3>Adresse:</h3><p>{{poi.adr}}</p></div>
      <div *ngIf="poi.links"><h3>Links:</h3><p>{{poi.links}}</p></div>
      <div *ngIf="poi.creators"><h3>Autor*innen:</h3><p>{{poi.creators}}</p></div>
      <div *ngIf="poi.credits"><h3>Credits:</h3><p>{{poi.credits}}</p></div>
      <div *ngIf="poi.dankan"><h3>Mit Dank an:</h3><p>{{poi.dankan}}</p></div>
    </div> -->

    <!-- Zentral im Modal: -->
    <div id="spotinfo" class="ui modal large modalSpotinfo">
      <div class="scrolling content">
          <div class="ui grid">
          <div class="sixteen column row headline">
              <div class="left floated thirteen wide column"><h1>{{poi.kurstitel}} (<span *ngIf="poi.jahr">{{poi.jahr}}, </span>{{poi.vhs}})</h1></div>
              <div class="right floated column"><p class="right"><i id="close" class="close icon"></i></p></div>
          </div>
          </div>
        <div class="ui grid stackable">
          <!-- <div class="fourteen wide column nbp"><h1>{{poi.kurstitel}} ({{poi.jahr}}, {{poi.vhs}})</h1></div>
          <div class="two wide column nbp closingbutton"><p class="right"><i id="close" class="close icon"></i></p></div> -->
          <div class="eleven wide column">
            <div class="media"></div>
            <div class="audio" *ngIf="'' != msbapAudioUrl">
              <div *ngIf="teaser_img" class="audio_img" (click)='clickTeaserImg()'><img [src]='teaser_img'/></div>
              <audio controls preload="auto" style="width:100%;" controlsList="nodownload">
                <source [src]="msbapAudioUrl" type="audio/mp3">
                Your browser does not support the audio element.
            </audio>
              <!-- <mat-basic-audio-player #audioplayer [audioUrl]="msbapAudioUrl" [title]="msbapTitle" [autoPlay]="false" muted="muted" [displayTitle]="msbapDisplayTitle" [displayVolumeControls]="msbapDisplayVolumeControls" controls></mat-basic-audio-player> -->
            </div>
            <div class="video">
              <video *ngIf="'' != videoUrl" [src]="videoUrl" [poster]="teaser_img" type='video/mp4' controls style="width:100%;" controlsList="nodownload"></video>
            </div>
          </div>

          <div class="five wide column">
            <h2>{{poi.titel}}</h2>
            <div [innerHTML]="poi.teaser"></div>
          </div>
            
          <div class="eleven wide column">
            <div *ngIf="poi.adr"><h3>Adresse:</h3><p [innerHTML]="poi.adr"></p></div>
            <div *ngIf="poi.creators"><h3>Autor*innen:</h3><p [innerHTML]="poi.creators"></p></div>
            <div *ngIf="poi.credits"><h3>Credits:</h3><p [innerHTML]="poi.credits"></p></div>
            <div *ngIf="poi.links"><h3>Links:</h3><p [innerHTML]="poi.links"></p></div>
            <div *ngIf="poi.dankan"><h3>Mit Dank an:</h3><p [innerHTML]="poi.dankan"></p></div>
            <div *ngIf="permalink"><h3>Link zu diesem Beitrag:</h3><p><a [href]="permalink" class="permalink">{{permalink}}</a></p></div>
          </div>
          
          </div>
        </div>
    </div>

    <div id="kursinfo" class="ui modal large modalKursinfo">
      <div class="scrolling content">
          <div class="ui grid">
          <div class="sixteen column row headline">
              <div class="left floated thirteen wide column"><h1>{{kurs.vhs}}</h1></div>
              <div class="right floated column"><p class="right"><i id="closeKurs" class="close icon"></i></p></div>
                
          </div>
          </div>
        <div class="ui grid stackable">
          <div class="eleven wide column">
            <div id="kursheader" class="eleven wide column">
              <h2>{{kurs.name}}</h2>
              <div *ngIf="teaser_img_kurs" id="teaser_img_kurs"><img class="teaser_img_kurs" [src]='teaser_img_kurs'/></div>
            </div>
            <div [innerHTML]="kurs.text"></div>
          </div>

          <div class="five wide column">
            <h2>Beiträge aus diesem Kurs:</h2>
            <div class="ui divided items"><div class="item" *ngFor="let o of kursbeitraege" (click)="selectMarker(o,1)" [class.selected]="o.titel === selectedPoi"><div class="content"><a>{{o.titel}}</a></div></div></div>
          </div>
            
          <div class="eleven wide column">
            <div *ngIf="kurs.links"><h3>Links:</h3><p [innerHTML]="kurs.links"></p></div>
            <div><h3>Link zu diesem Kurs:</h3><p><a [href]="kurs.permalink" class="permalink">{{kurs.permalink}}</a></p></div>
          </div>
          
          </div>
        </div>
    </div>

    <agm-map 
      [latitude]="lat" 
      [longitude]="lng"
      [zoom]="zoom"
      [disableDefaultUI]="false"
      [styles]="mapStyle"
      [fitBounds]="true"
      [maxZoom]="20"
      (mapClick)="mapClicked($event)">
        <!-- <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker> -->
        <agm-kml-layer [url]="'https://wissensrouten-karte.de/api/projekte/wissensrouten/hessen2.kml'"></agm-kml-layer>
        <agm-marker-cluster imagePath="https://raw.githubusercontent.com/googlemaps/v3-utility-library/master/markerclustererplus/images/m">
          <agm-marker 
            *ngFor="let m of activeMarkers; let i = index"
            (markerClick)="clickedMarker(m, i)"
            (mouseOver)="onMouseOver(infoWindow, $event,m)" 
            (mouseOut)="onMouseOut(infoWindow, $event,m)"
            [latitude]="m.lat"
            [longitude]="m.lng"
            [agmFitBounds]="true"
            [iconUrl]= "getIcon(m.icon)"
            [label]=""
            [markerDraggable]="false"
            (dragEnd)="markerDragEnd(m, $event)">
          <!-- <agm-info-window #infoWindow><div class="iw"><p>{{m.titel}}</p></div></agm-info-window> -->
          <agm-snazzy-info-window [latitude]="m.lat" [longitude]="m.lng" [closeWhenOthersOpen]="true" [closeOnMapClick]="true" [showCloseButton]="false" [shadow]="true"
          backgroundColor="rgba(48, 88, 138, 0.9)"
          #infoWindow class="infoWindow">
              <ng-template>
                  {{m.titel}}
              </ng-template>
            </agm-snazzy-info-window>
        </agm-marker>    
        </agm-marker-cluster>
        <agm-data-layer *ngIf="showTrack" [geoJson]="track" [style]="styleGeojson"></agm-data-layer>
        <agm-kml-layer *ngIf="showKmlTrack" [url]="kml"> </agm-kml-layer> 
    </agm-map>

  </div><!-- Menu end: -->
</div>