<div class="ui fixed inverted menu tablet computer only">
    <div class="ui container">
        <a routerLink="/" class="header item">
        <!-- <img class="logo" src="assets/images/logo.png"> -->
        Wissensrouten
        </a>
        <a routerLink="/redaktion"  class="item">Beiträge</a>
        <!-- <div class="ui simple dropdown item">
                <a routerLink="/beitraege">Beiträge </a>
                <i class="dropdown icon"></i>
                <div class="menu">
                    <a class="item" routerLink="/beitraege">Alle</a>
                    <div class="divider"></div>
                    <a class="item" routerLink="/">Nach Kursen</a>
                    <a class="item" routerLink="/">Nach VHS</a>
                </div> 
        </div>-->
        <!-- <a routerLink="/kurse"  class="item">Kurse</a> -->
        <a routerLink="/redaktion/kurse" class="item">Kurse</a>
        <a routerLink="/redaktion/vhs" class="item">VHS</a>
        <a routerLink="/redaktion/nutzerverwaltung" class="item">Nutzerverwaltung</a>
        <a routerLink="/" class="item">Karte</a>
        <a class="item" (click)="logout()"><i class="icon user circle"></i>Logout</a>
    </div>
</div>

    <div class="ui mobile only grid">
        <div class="ui top fixed inverted borderless menu">
        <a class="header item">Project Name</a>
        <div class="right menu">
            <div class="item">
            <button class="ui icon toggle basic inverted button">
                <i class="content icon"></i>
            </button>
            </div>
        </div>
        <div class="ui vertical inverted borderless fluid menu">
            <a class="active item">Home</a> <a class="item">About</a>
            <a class="item">Contact</a>
            <div class="ui dropdown item">
            Dropdown <i class="dropdown icon"></i>
            <div class="menu">
                <a class="item"> Action </a> <a class="item"> Another action </a>
                <a class="item"> Something else here </a>
                <div class="ui divider"></div>
                <div class="header">Navbar header</div>
                <a class="item"> Seperated link </a>
                <a class="item"> One more seperated link </a>
            </div>
            </div>
        </div>
    </div>
</div>

<!-- Menüpunkt mit Untermenü
<div class="ui simple dropdown item">
    Kurse <i class="dropdown icon"></i>
    <div class="menu">
        <a class="item" href="#">Link Item</a>
        <a class="item" href="#">Link Item</a>
        <div class="divider"></div>
        <div class="header">Header Item</div>
        <div class="item">
        <i class="dropdown icon"></i>
        Sub Menu
        <div class="menu">
            <a class="item" href="#">Link Item</a>
            <a class="item" href="#">Link Item</a>
        </div>
        </div>
        <a class="item" href="#">Link Item</a>
    </div>
</div> -->