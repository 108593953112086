import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
declare var $: any;

@Component({
  selector: 'app-org-list',
  templateUrl: './org-list.component.html',
  styleUrls: ['./org-list.component.scss']
})
export class OrgListComponent implements OnInit {
  elements: any [];
  modalTitel: string;
  modalId : number;
  constructor(private apiService: ApiService) { }

  ngOnInit() {
    this.apiService.getVhsn().subscribe((res)=>{ this.elements = res; });
  }
  delete(id){
    // console.log(id);
    this.apiService.deleteVhs(id).subscribe((res)=>{ 
      if(res) console.log(res); 
      this.apiService.getVhsn().subscribe((res)=>{ this.elements = res; });
    });
    
  }
  openModal(o){
    this.modalTitel = o.name;
    this.modalId = o.id;
    $('.ui.modalDeleteOrg').modal('show');
  }
}
