<app-top-nav></app-top-nav>
<div class="ui container main">
    <div class="ui small breadcrumb">
            <a class="section" routerLink="/redaktion">Übersicht</a><i class="right chevron icon divider"></i>
            <a class="section" routerLink="/redaktion/kurse">Alle Kurse</a><i class="right chevron icon divider"></i>
            <div class="active section">{{o.name}}</div>
    </div>
    <h1 class="ui header">{{sitetitle}}</h1>
    <form [formGroup]="inForm" class="ui form" (ngSubmit)="onSubmit()">

        <input type="hidden" formControlName="id">
        <input type="hidden" formControlName="status">
        <div class="field required"><label>Name</label><input type="text" formControlName="name"></div>
        <div class="field"><label>Kurz-URL zu diesem Kurs:</label><input type="text" formControlName="shorturl"></div>
        <div><p>Direktlink zu allen Beiträgen des Kurses: <a [href]="deeplink" target="_blank">{{deeplink}}</a></p><p> </p></div>
        <input type="hidden" formControlName="vhs">
        <div class="field required"><label>Zugehörige VHS:</label>
            <div class="ui selection dropdown">
                <input type="hidden" name="vhsid" formControlName="vhsid" (change)="onChangeVhs($event.target.value)">
                <i class="dropdown icon"></i>
                <div class="default text">{{defaultText}}</div>
                <div class="menu">
                    <div [ngClass]="(vhs.id== id)?'item active selected':'item'" *ngFor="let vhs of vhsn" [attr.data-value]="vhs.id" >{{vhs.name}}</div>
                </div>
            </div>
        </div>
        <div class="ui segment">
        <div class="ui toggle checkbox">
            <input type="checkbox" name="showkursinfo" formControlName="showkursinfo">
            <label>Das Infofenster für diesen Kurs auf der Karte anzeigen.</label>
          </div>
        </div>
        <div class="ui segment">
            <div class="field"><label>Beitragsbild (möglichst 735 * 240):</label><br><input type="text" formControlName="teaser_img" value="{{o.teaser_img}}"></div>
            <input type="file" name="f" (change)="onFileSelected($event)">
            <div class="ui button green" (click)="onUpload()">Bild hochladen</div>
        </div>

        <div class="field"><label>Beschreibungstext:</label><editor formControlName="text" [init]="{base_url: '/tinymce', suffix: '.min', menubar:false, branding: false, language: 'de', language_url: '../../assets/exogen/tinyMCElangs/de.js', plugins: 'lists advlist link wordcount', toolbar: 'undo redo | bold italic | bullist numlist outdent indent | link'}"></editor></div>
        <div class="field"><label>Weiterführende Links:</label><editor formControlName="links" [init]="editorConfig"></editor></div>
        <div class="field required"><label>Soll eine zugehörige Route angezeigt werden?:</label>
            <div class="ui selection dropdown">
                <input type="hidden" name="routentyp" formControlName="routentyp" (change)="onChangeRoutentyp($event.target.value)">
                <i class="dropdown icon"></i>
                <div class="default text">{{defaultText2}}</div>
                <div class="menu">
                    <div [ngClass]="(routentyp == rt)?'item active selected':'item'" *ngFor="let rt of routentypen" [attr.data-value]="rt" >{{rt}}</div>
                </div>
            </div>
            <input type="hidden" name="routenreihenfolge" formControlName="routenreihenfolge">
        </div>

        <div *ngIf="routentyp===1"><h3>Bitte legen Sie die Reihenfolge der Route fest:</h3>
            <div id="reihenfolgeroute" class="ui list divided" [sortablejs]="beitraege" [sortablejsOptions]="options">
                <div *ngFor="let b of beitraege" class="item">
                    <div class="right floated content">
                        <div *ngIf="b.route" class="ui button green" (click)="removePoi(b.id)">Teil der Route</div>
                        <div *ngIf="!b.route" class="ui button" (click)="addPoi(b.id)">nicht Teil der Route</div>
                      </div>
                    <i class="map marker icon"></i>
                    <div class="content">
                      <a class="header">{{b.titel}}</a>
                      <div class="description">{{b.teaser}}</div>
                    </div>
                  </div>
            </div>
        </div>

        <div *ngIf="routentyp===2"><h3>Hier können Sie Ihren GPS-Track im KML-Format hochladen:</h3>
            <div class="ui segment">
                <div class="field"><label>KML-Datei:</label><br><input type="text" formControlName="gps" value="{{o.gps}}"></div>
                <input type="file" name="gps" (change)="onFileSelectedGps($event)">
                <!-- <div class="ui button green" (click)="onUploadGps()">GPS-Track hochladen</div> -->
                <input type="hidden" formControlName="kml">
                <input type="hidden" formControlName="gpx">
            </div>
        </div>

        <button class="ui submit primary button" type="submit" [disabled]="!inForm.valid">Speichern</button>
    </form>
    <p id="message">Die Daten wurden gespeichert.</p>
</div>
<app-footer></app-footer>