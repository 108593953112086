<div class="ui middle aligned center aligned grid">
    <div class="column">
      <h2 class="ui image header">
        <!-- <img src="assets/images/logo.png" class="image"> -->
        <div class="content">
          Bitte melden Sie sich an:
        </div>
      </h2>
      <form class="ui large form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div class="ui stacked segment">
          <div class="field">
            <div class="ui left icon input">
              <i class="user icon"></i>
              <input type="text"formControlName="username" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }">
              <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
                <div *ngIf="f.username.errors.required">Bitte geben Sie einen Login-Namen ein.</div>
            </div>
            </div>
          </div>
          <div class="field">
            <div class="ui left icon input">
              <i class="lock icon"></i>
              <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
              <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Bitte geben Sie ein Passwort ein.</div>
              </div>
            </div>
          </div>
          <!-- <div class="ui fluid large submit button">Login</div> -->
          <button [disabled]="loading" class="ui blue button">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Login
        </button>
        <div *ngIf="error" class="alert alert-danger mt-3 mb-0">{{error}}</div>
        </div>

  
      </form>

    </div>
  </div>
