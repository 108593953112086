<!-- <div class="ui container"> -->

<!-- <mat-toolbar color="primary">
  <mat-toolbar-row>
    <a class="top-logo" routerLink="/"><span>Navigation</span></a>
    <span class="menu-spacer"></span>
    <mat-icon class="menu-icon" [matMenuTriggerFor]="menu">menu</mat-icon>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="/karte">Karte</button>
      <button mat-menu-item>Item 2</button>
    </mat-menu> 
    <a class="toolbar-icon" routerLink="/karte"><mat-icon class="map">map</mat-icon></a>
    <a class="toolbar-icon" routerLink="/login"><mat-icon class="account_circle">account_circle</mat-icon></a>
  </mat-toolbar-row>
</mat-toolbar>-->

<!-- <div class="ui menu " id="map-top-menu">
  <a class="header item"><img id="Undine-Logo_Header" src="/assets/img/Undine2.png"/> UNDINE II</a>
  <div class="ui simple dropdown item">
    vhs <i class="dropdown icon"></i>
    <div class="menu">
      <div class="ui checkbox toggle item" *ngFor="let i of vhs" (click)="toggleOverlays($event, i)">
          <input type="checkbox" tabindex="0" id="vhs_{{i.id}}">
          <label>{{i.name}}</label>
      </div>
    </div>
  </div>

  <div class="ui simple dropdown item">
        Kurse <i class="dropdown icon"></i>
        <div class="menu">
            <div class="ui checkbox radio item" *ngFor="let i of kurse" (click)="toggleOverlays($event, i)">
                  <label>{{i.kurztitel}}</label>
            </div>
        </div>
  </div>
  <div class="right menu">
    
  </div>
</div> -->

<router-outlet></router-outlet>

<!-- </div> -->