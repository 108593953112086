<app-top-nav></app-top-nav>
<div class="ui container main">

        <div class="ui small breadcrumb">
        <a class="section" routerLink="/redaktion">Übersicht</a><i class="right chevron icon divider"></i>
        <div class="active section"> Alle Kurse</div>
        </div>

        <h1 class="ui header">Alle Kurse</h1>
        <div class="ui basic button" routerLink="/redaktion/kurs/neu" ><i class="icon plus"></i>Neuen Kurs anlegen</div>
        <table class="ui compact celled definition table">
        <thead>
        <tr>
                <!-- <th>Sichtbar</th> -->
                <th></th>
                <th>Titel</th>
                <th>VHS</th>
                <th></th>
                <th></th>
        </tr>
        </thead>
        <tbody>
                <tr *ngFor="let o of layer">
                        <!-- <td class="collapsing"><div class="ui fitted slider checkbox"><input type="checkbox"> <label></label></div></td> -->
                        <td></td>
                        <td><a routerLink="/redaktion/kurs/{{o.id}}">{{o.name}}</a></td>
                        <td><a routerLink="/redaktion/vhs/{{o.vhsid}}">{{o.vhs}}</a></td>
                        <td><a routerLink="/redaktion/kurs/{{o.id}}"><i class="edit icon"></i></a></td>
                        <td><i (click)="openModal(o)" class="trash alternate outline icon"></i></td>
                </tr>
        </tbody>
        </table>
</div>
<app-footer></app-footer>

<div class="ui modal modalDeleteLayer">
        <div class="ui icon header"><i class="trash alternate outline icon"></i>Kurs löschen</div>  
        <div class="content"><p>Sind Sie sicher, dass sie den folgenden Kurs jetzt löschen möchten?</p><h3>{{modalTitel}}</h3></div>
        <div class="actions">
                <div class="ui black deny button">Nein, abbrechen</div>
                <div class="ui positive right labeled icon button" (click)="delete(modalId)">Ja, jetzt löschen<i class="checkmark icon"></i></div>
        </div>
</div>