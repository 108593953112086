import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RecoverPasswordComponent } from './recover-password/recover-password.component';
import { AuthGuard } from './_helpers';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'anmelden', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'registrieren', component: RegisterComponent },
  { path: 'recover-password', component: RecoverPasswordComponent },
  { path: 'passwort-vergessen', component: RecoverPasswordComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
