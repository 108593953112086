import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import { HttpClient, HttpHeaders} from '@angular/common/http';
declare var $: any;

@Component({
  selector: 'app-backenduser',
  templateUrl: './backenduser.component.html',
  styleUrls: ['./backenduser.component.scss']
})
export class BackenduserComponent implements OnInit {
  users: any [];
  masterpw: string = "ep213go";
  modalTitel: string;
  modalpw : number;
  pw: boolean = false;
  wrongpw: boolean = false
  userForm = this.fb.group({
    ologin: [''],
    nlogin: [''],
    npw: ['']
  });

  constructor(private apiService: ApiService, private http: HttpClient, private fb: FormBuilder) { }

  ngOnInit(): void {
  }

  openModal(o){
    this.userForm = this.fb.group({
      ologin: new FormControl(o.username,[Validators.required]),
      nlogin: new FormControl(o.username,[Validators.required]),
      npw: new FormControl(o.pw,[Validators.required])
     });
    this.modalTitel = o.username;
    $('.ui.modalEdit').modal('show');
  }

  openModalTrash(o){
    this.modalTitel = o.username;
    this.modalpw = o.pw;
    $('.ui.modalDelete').modal('show');
  }

  loginM(){
    let pw = $('#pw').val();
    if(pw == this.masterpw){
      this.pw = true;
      this.apiService.getUsers(this.masterpw).subscribe((res)=>{ 
        // if res !=
        this.users = res; });
    }
    else{
      this.wrongpw = true;
    }
  }

  updateUser(){
      console.log('Form submitted');
      //console.log(this.inForm.value);
      let el = this.userForm.value;
      el['masterpw']=this.masterpw;
      this.apiService.updateUser(el).subscribe((res)=>{ 
        if(res){
          $('body').toast({class: 'success',message: `gespeichert`});
          this.apiService.getUsers(this.masterpw).subscribe((res)=>{ 
            // if res !=
            this.users = res; });
        }
        else{ 
          $('body').toast({class: 'error',message: `Es ist ein Fehler aufgetreten.`}); 
        }
      });
  }

}
