import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../api.service';
import { FormGroup, FormControl, FormBuilder, Validators  } from '@angular/forms';
import { Router} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-org-details',
  templateUrl: './org-details.component.html',
  styleUrls: ['./org-details.component.scss']
})
export class OrgDetailsComponent implements OnInit, OnDestroy {
  id: number;
  sitetitle: string;
  private sub: any;
  public o: any = [];

  deeplink: string = '';

  inForm = this.fb.group({ 
    id: [''],
    name: [''],
    shorturl:new FormControl('',[this.validateShortUrl.bind(this)]),
    status: [''],
    text:['']
  });

  constructor(private route: ActivatedRoute, private apiService: ApiService, private fb: FormBuilder, private router: Router) { }

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      if(params['id'] != 'neu'){
        this.sitetitle = 'VHS bearbeiten';

        this.id = +params['id']; // (+) converts string 'id' to a number
        this.apiService.getVhs(this.id).subscribe((res)=>{ 
          this.o = res; 
          if(this.o.shorturl == null){this.o.shorturl ='';}
          this.inForm = this.fb.group({ 
            id: [this.o.id],
            name: [this.o.name],
            shorturl: new FormControl(this.o.shorturl,[this.validateShortUrl.bind(this)]),
            status: [this.o.status],
            text: [this.o.text]
          });
        });
      }else{
        this.sitetitle = 'Neue VHS anlegen';
        this.id = 0; // 0 = neu
        this.inForm.patchValue({'id': 0});
        this.inForm.patchValue({'status': 1});
        this.inForm.patchValue({'text': ''});
        this.inForm.patchValue({'shorturl': ''});
      }
   });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  onSubmit(){
    // console.log('Form submitted');
    //console.log(this.inForm.value);
    this.apiService.updateVhs(this.inForm.value).subscribe((res)=>{ });
    this.router.navigate(['/redaktion/vhs']);
  }

  /* Validation  +++ */
  validateShortUrl(control: FormControl): {[s: string]: boolean}{

      // Trimmen:
      var vorher = control.value.trim();
      // URL-Sonderzeichen nicht zulassen:
      let re = /[?:@&=+$#'"]/g;
      let nachher = vorher.replace(re,'');
      // Leerzeichen durch Untertsirche ersetzen:
      let re2 = /\s/gi
      nachher = nachher.replace(re2,'_');
      // Generell ersetzen:
      var searchAndReplace: any = {ä:"ae",Ä:"Ae",ü:"ue",Ü:"Ue",ö:"oe",Ö:"Oe",ß:"ss"};
      for(var s in searchAndReplace){
        //console.log(s+' '+searchAndReplace[s]);
        let re3 = new RegExp(s,"g");
        nachher = nachher.replace(re3,searchAndReplace[s]);
      }
      // console.log('Validate shorturl: '+nachher);
      if(nachher !== vorher){ // Ändert nur, falls notwendig. Verhindert eine rekursive Schleife.
        this.inForm.patchValue({'shorturl': nachher}, {emitEvent:false});
      }
      
      if(nachher !== ''){
        this.deeplink = "https://www.wissensrouten-karte.de/vhs/"+nachher;
      }
      else{
        this.deeplink = "https://www.wissensrouten-karte.de/vhs/"+this.id;
      }
      
    return null;
  }

}
